import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import hillo from "hillo"
import weekday from "@/dataLayer/model/weekday"
import i18n from "@/i18n"
import {getCurrentRestaurantInfo} from '@/dataLayer/repository/restaurantInfo'
import dayjs from 'dayjs'
import {keyBy, sortBy} from "lodash-es";
import {DeliveryMethod} from "@/dataLayer/service/OrderService";
import {Remember} from "@/locales";

let infoObject = null

async function loadRestaurantInfo() {
    infoObject = await hillo.get(GlobalConfig.cloudRoot + 'data/dataTerminal/' + GlobalConfig.dataTerminalId)
}


export async function getOpeningTime(deliveryMethod = DeliveryMethod.delivery) {
    const key = deliveryMethod === DeliveryMethod.delivery ? 'openingTime' :
        'pickupOpeningTime'
    const timeRes = (await getRestaurantInfo(Remember.requestLanguage, key))
    if (timeRes) {
        const openTimeList = []
        timeRes.forEach((item) => {
            item.name = weekday.getList().find(n => n.id === parseInt(item.weekday)).name
            const openTime = [transHourToMin(item.startTime, 0), transHourToMin(item.endTime, 0)]
            let index = -1
            let haveExists
            haveExists = openTimeList.some((newItem, j) => {
                if (item.name === newItem.weekday) {
                    index = j
                    return true
                }
            })
            if (!haveExists) {
                const tempArr = []
                tempArr.push(openTime)
                openTimeList.push({
                    id: item.weekday,
                    weekday: item.name,
                    openTime: tempArr
                })
            } else {
                openTimeList[index].openTime.push(openTime)
            }
        })
        openTimeList.sort((a, b) => (a.id === b.id) ? 0 : (a.id > b.id ? 1 : -1))
        for (const item of openTimeList) {
            item.openTime = sm2(item.openTime)
            item.openTime.forEach((t) => {
                t[0] = TransMinToHours(t[0])
                t[1] = TransMinToHours(t[1])
            })
        }
        return openTimeList
    }
    return []
}

export function getFutureWeek() {
    const res = []
    for (let i = 0; i < 7; i++) {
        if (i === 0) {
            res.push({text: i18n.t('Today'), value: i})
            if (GlobalConfig.onlyOrderToday.toString() === 'true') {
                return res
            }
        } else {
            const [day, time] = dayjs().add(i, 'd').format('dddd DD.MM').split(' ')
            const weekDayTime = i18n.t(day) + ' ' + time
            res.push({text: weekDayTime, value: i})
        }
    }
    return res
}

export async function getRestaurantInfo(lang, key) {
    if (infoObject === null) {
        await loadRestaurantInfo()
    }
    lang = lang.toUpperCase()
    return JSON.parse(infoObject[lang][key]).content
}

const dishListCache = []

const categoryCache = []

export async function getCategoryWithDishes(lang) {
    if (categoryCache.length === 0) {
        if (infoObject === null) {
            await loadRestaurantInfo()
        }

        lang = lang.toUpperCase()
        const imageCache = keyBy(await getImageCache(), "dishesId")
        categoryCache.push(...(await getRestaurantInfo(lang, 'category'))
            .filter(c => c.dishes.length > 0)
            .sort((a, b) => {
                const rank = '10,9,8,11,13'.split(',')
                const idToRank = (id) => {
                    return 10 - rank.indexOf(id)
                }
                const [ra, rb] = [a.dishesCategoryTypeId, b.dishesCategoryTypeId].map(idToRank)
                return ra > rb ? -1 : 1
            })
            .map(c => {
                c.dishes = c.dishes.filter(it => !it.code.startsWith('ea'))
                    .map(d => {
                        d.dishName = d.dishName ?
                            d.dishName.replaceAll('[/chili/]', '🌶️')
                                .replaceAll('[/gutou/]', '🦴') : ''
                        d.count = 0
                        d.modShow = false
                        d.cloudImage = imageCache[d.dishId]?.imagePath
                        d.note = ''
                        return d
                    })
                dishListCache.push(...c.dishes)
                c.categoryName = c.langs[0].name
                c.desc = c.langs[0].desc
                return c
            }).filter(c => c.dishes.length > 0))

    }

    return categoryCache
}

export async function getAllDishList() {
    if (dishListCache.length === 0) {
        await getCategoryWithDishes('DE')
    }
    return dishListCache
}

export function getDistance(lat1, lng1, lat2, lng2) {
    const radLat1 = lat1 * Math.PI / 180.0
    const radLat2 = lat2 * Math.PI / 180.0
    const a = radLat1 - radLat2
    const b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0
    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
    s = s * 6378.137 // EARTH_RADIUS
    s = Math.round(s * 10000) / 10000
    return s
}

export async function calculateDeliveryRule(addressData) {
    const deliveryDistance = await getDistanceFromRestaurant(addressData)
    const deliveryRules = {
        // basicInfo*/
        ruleName: '',
        startPrice: '',
        freePrice: '',
        priceMod: '',
        hint: '',
        deliveryDistance
    }
    let canDelivery = true
    const deliveryRes = (await getRestaurantInfo(Remember.requestLanguage, 'deliveryArea'))

    const postcode = addressData.plz
    const postcodeDeliveryRule = deliveryRes.find((item) => item.PLZArea.indexOf(postcode) !== -1)
    if (typeof (postcodeDeliveryRule) === 'undefined') {
        const distanceRule = deliveryRes.filter((item) => item.PLZArea.indexOf('km') !== -1)
        const distances = distanceRule.map(it => ({
            distance: parseFloat(it.PLZArea.replace('km', '')),
            ...it,
        }))
        const sorted = sortBy(distances, 'distance')
        const rule = sorted.find(it => {
            return it.distance > deliveryDistance
        })
        if (!rule) {
            canDelivery = false
            deliveryRules.hint = i18n.t('AddressIsNotInDeliveryRange')
        } else {
            deliveryRules.ruleName = rule.name
            deliveryRules.startPrice = rule.startPrice
            deliveryRules.freePrice = rule.freePrice
            deliveryRules.priceMod = rule.priceMod
            deliveryRules.range = rule.PLZArea.replace('km', '')
            deliveryRules.hint = i18n.t('in_the_range_of_delivery') + deliveryRules.range + 'KM, ' + i18n.t('current_distance') + deliveryDistance + 'KM. '
            canDelivery = true
            if (deliveryRules.priceMod === 0) {
                deliveryRules.hint += i18n.t('we_offer_free_delivery')
            } else {
                deliveryRules.hint += `${i18n.t('delivery_fee') + deliveryRules.priceMod}€. `
            }
        }
    } else {
        deliveryRules.ruleName = postcode
        deliveryRules.startPrice = postcodeDeliveryRule.startPrice
        deliveryRules.freePrice = postcodeDeliveryRule.freePrice
        deliveryRules.priceMod = postcodeDeliveryRule.priceMod
        deliveryRules.hint = i18n.t('BasicCharge') + ':' + localStorage.getItem('startPrice') + '€. ' + i18n.t('MinimumOrderValue') + ':' + deliveryRules.freePrice + '€. ' + i18n.t('DeliveryCost') + ':' + deliveryRules.priceMod + '€.'
    }
    deliveryRules.canDelivery = canDelivery
    return deliveryRules
}

export function transHourToMin(timeStr, devMin = 0) {
    const tArr = timeStr.split(':')
    const a = parseInt(tArr[0])
    const b = parseInt(tArr[1])
    return (a * 60 + b + devMin)
}

export function TransMinToHours(val) {
    const a = parseInt(val / 60)
    let b = val % 60
    if (b < 10) {
        b = '0' + b
    }
    const temArr = []
    temArr.push(a)
    temArr.push(b)
    return temArr.join(':')
}

export async function getDistanceFromRestaurant(addressInfo) {
    const restaurantInfo = await getCurrentRestaurantInfo()
    return getDistance(addressInfo.latitude ?? 0, addressInfo.longitude ?? 0,
        restaurantInfo.latitude ?? 0, restaurantInfo.longitude ?? 0)
}

export function calculateDeliveryCost(deliveryRules, cartTotal, pickUp) {
    let deliveryFee = 0
    if (pickUp === true) {
        deliveryFee = 0
    } else {
        if (parseFloat(deliveryRules.freePrice) === 0 || (cartTotal < deliveryRules.freePrice)) {
            deliveryFee = deliveryRules.priceMod
        } else if (cartTotal >= deliveryRules.freePrice) {
            deliveryFee = 0
        }
    }
    return deliveryFee
}

export function sm2(spans, precision = 0) {
    if (spans.length < 2) return spans
    const set = {}
    spans.forEach(span => (set[span[0]] === undefined || set[span[0]] < span[1]) && (set[span[0]] = span[1])) // 首相同取最大，去重
    const raw = []
    for (const i in set) raw.push([parseInt(i), set[i]]) // 插入数组
    return raw.sort((a, b) => a[0] - b[0]) // 按首排序
        .slice(1)
        .reduce((prev, cur) => { // 若现首大于前尾则插入，否则合并
            cur[0] - prev[prev.length - 1][1] > precision
                ? prev.push(cur)
                : prev[prev.length - 1] = cur[1] > prev[prev.length - 1][1]
                    ? [prev[prev.length - 1][0], cur[1]]
                    : prev[prev.length - 1]
            return prev
        }, [raw[0]])
}

export function calTime(Arr, gap) {
    const temp = []
    Arr.forEach(item => {
        item[0] = TransMinToHours(item[0])
        item[1] = TransMinToHours(item[1])
        const amount = (transHourToMin(item[1], 0) - transHourToMin(item[0], 0)) / gap
        let sTime = item[0]
        for (let i = 0; i < amount; i++) {
            if (i === 0) {
                temp.push(sTime)
                const tArr = sTime.split(':')
                let a = parseInt(tArr[0])
                let b = parseInt(tArr[1])
                if (b < 16) {
                    b = 15
                } else if (b < 31) {
                    b = 30
                } else if (b < 46) {
                    b = 45
                } else {
                    a = a + 1
                    b = 0
                }
                const temArr = []
                temArr.push(a)
                if (b === 0) {
                    b = '00'
                }
                temArr.push(b)
                sTime = temArr.join(':')
            } else {
                temp.push(sTime)
                const totalTime = transHourToMin(sTime, gap)
                sTime = TransMinToHours(totalTime)
            }
        }
        temp.push(item[1])
    })
    return temp
}


export async function getOpeningTimeForWeekDay(weekday, deliveryMethod) {
    const time = await getOpeningTime(deliveryMethod)

    const timeArr = (time).find(item => parseInt(item.id) === parseInt(weekday))?.openTime
        ?.map(d => d.map(c => transHourToMin(c, 0))) ?? []

    if (dayjs().day() === parseInt(weekday) % 7) {
        const NowTime = dayjs().format('HH:mm')
        const a = transHourToMin(NowTime, 10)
        const bArr = timeArr.map(function (timeItem) {
            if (timeItem[0] > a) {
                return timeItem
            } else if ((timeItem[0] < a) && (timeItem[1] > a)) {
                timeItem[0] = a
                return timeItem
            }
        }).filter(it => it)
        if (bArr.length === 0) {
            return []
        } else {
            // gap为时间段之间的间隔时间，默认为15
            return calTime(bArr, 15)
        }
    } else { // 不是当日可以选全天时间，不需要额外计算时间段
        return calTime(timeArr, 15)
    }
}

export function isInOpenTime(targetTime, arr) {
    return arr?.some(a => {
        const [start, end] = a.map(t => transHourToMin(t, 0))
        const time = transHourToMin(targetTime, 0)
        return time >= start && time <= end
    }) ?? false
}

export function getWeekDay() {
    let targetWeekday = dayjs().day()
    if (targetWeekday === 0) {
        targetWeekday = 7
    }
    return targetWeekday
}

export const cloudUrl = 'https://cloud5.api.aaden.io'

export async function getBaseAndUrlForDeviceId(deviceId) {
    const url = (await findDeviceByDeviceId(deviceId))
        ?.baseUrl ?? getNgrokUrl(deviceId)
    return {
        deviceId,
        url
    }
}

export function getNgrokUrl(deviceId) {
    deviceId = deviceId.toString()
    return `${location.protocol}//ik${deviceId.padStart(4, '0')}.ngrok.aaden.io`
}

export async function findDeviceByDeviceId(deviceId) {
    try {
        return (await hillo.jsonPost(cloudUrl + '/virtualDevice/search', {deviceId: deviceId})).data?.[0]
    } catch (e) {
        console.log(e)
        return null
    }
}

export async function getImageCache() {
    try {
        return await hillo.get("https://cloud-v2.aaden.io/api/dish-images/by-device/" + GlobalConfig.deviceId)
    } catch (e) {
        return []
    }

}

/**
 * @param {{deviceId: number}} data
 */
export async function reportToCloud(data) {
    const defaultData = {
        name: '', ip: '', uuid: '', version: '', frontendType: '', deviceId: '',
    };
    const {
        name,
        ip,
        uuid,
        version,
        frontendType,
        deviceId,
    } = Object.assign({}, defaultData, data);
    const frontendLogDTO = {
        name,
        ip,
        uuid,
        version,
        frontendType,
        deviceId,
        timestamp: dayjs().valueOf()
    };
    await hillo.jsonPost("https://cloud-v2.aaden.io/api/frontend-logs/save", frontendLogDTO)
}

