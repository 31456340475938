import {checkNewRecords} from "@/plugins/fire-base";
import {getBaseAndUrlForDeviceId, reportToCloud} from "@/dataLayer/service/api";
import {AadenBaseConfig} from "aaden-base-model/lib/AadenSettingManager";
import router from "../../plugins/router";
import {getCurrentRestaurantInfo} from "@/dataLayer/repository/restaurantInfo";

const defaultConfig = require('@/assets/config.json')
let GlobalConfig = Object.assign({}, defaultConfig)
window.Config = GlobalConfig


export async function loadConfig() {
    const shortName = location.hostname.split('.')[0]
    console.log(shortName)
    const realName = ['localhost', 'aaden-vue-takeaway-five', '192'].includes(shortName) ? 'innerken' : shortName
    console.log(await checkNewRecords())
    const newRecords = (await checkNewRecords()).find(s => s.subdomainName.toLowerCase() === realName)
    let records
    if (newRecords) {
        records = newRecords
        GlobalConfig.SaaSVersion = true
    }
    console.log(records, realName, 'records')
    GlobalConfig = Object.assign(GlobalConfig, await AadenBaseConfig(defaultConfig))
    if (records) {
        GlobalConfig.dataTerminalId = records.dataTerminalId
        GlobalConfig.deviceId = records.deviceId
        GlobalConfig.shopHomepageUrl = records.shopHomepageUrl
        GlobalConfig.delivery = records?.hideDelivery === 'false' ?? false
        GlobalConfig.selfPickUp = records?.selfPickUp === 'true' ?? true
        GlobalConfig.shopHomepageUrl = records.shopHomepageUrl ? 'http://' + records.shopHomepageUrl : "http://www.aaden-pos.com/en"
        GlobalConfig.pickDiscountStr = records.pickDiscountStr ? records.pickDiscountStr : ''
        GlobalConfig.onlyOrderToday = records.onlyOrderToday ? records.onlyOrderToday : false
        if (records.bannerImagePath) {
            GlobalConfig.bannerPath = records.bannerImagePath
        }
    } else {
        await router.push('/Error')
    }
    console.log(GlobalConfig.delivery)
    console.log(GlobalConfig.selfPickUp)
    const realUrl = (await getBaseAndUrlForDeviceId(records?.deviceId ?? GlobalConfig.deviceId)).url
    GlobalConfig.imageRoot = getImgRoot(realUrl);
    GlobalConfig.resourceRoot = realUrl + "/Resource/";
    try {
        await reportToCloud({
            deviceId: GlobalConfig.deviceId,
            ip: "",
            uuid: location.host,
            version: require("../../../package.json").version,
            frontendType: "takeaway-1.1",
            name: (await getCurrentRestaurantInfo()).name,
        })
    } catch (e) {
        console.log(e)
    }
}

export function getImgRoot(base) {
    return base + GlobalConfig.imgRoot;
}


export default GlobalConfig
