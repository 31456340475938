<template>
  <v-bottom-sheet
      width="100vw"
      max-width="600px"
      v-model="realShow">
    <v-card
        v-if="dish"
        id="card"
        style="position: relative"
        class="d-flex flex-column align-center"
        max-height="calc(100vh - 24px)">
      <div style="max-height: calc(100vh - 24px);overflow-y: scroll;width: 100%">
        <div
            class="pa-4 white"
            style="width: 100%;position: sticky;top: 0;z-index: 100">
          <div class="text-h5  font-weight-black">{{ dish.dishName }}</div>

        </div>
        <div
            class="text-body-2 text--secondary mt-1 px-4"
            v-html="dish.dishDesc"></div>
        <div class="pa-4">
          <v-img
              width="100%"
              :aspect-ratio="16/12"
              v-if="haveImage"
              :src="dish.cloudImage||(imageRoot+dish.image)">
            <template #placeholder>
              <v-card>
                <v-responsive :aspect-ratio="1">
                  <div
                      style="height: 100%"
                      class="d-flex align-center justify-center">
                    <v-icon large>mdi-image</v-icon>
                  </div>
                </v-responsive>
              </v-card>
            </template>
          </v-img>
        </div>
        <template v-if="dish.modInfo&&show&&computedOption.length>0">
          <div class="text-body-2 px-4 font-weight-black mt-8 d-flex">
            🥗 {{ $t('Customize') }}
            <v-spacer></v-spacer>
            <template v-if="attrLimit!==-1">
              {{ multipleSelected }}/{{ attrLimit }}
            </template>
          </div>

          <div
              class="pb-16 px-4 mt-4"
              style="width: 100%;">
            <template v-for="item in computedOption">
              <div
                  class="d-flex align-center text-caption font-weight-black mb-1"
                  :key="'mod2head'+item.id">
                {{ item.name }}
              </div>
              <template v-if="item.multiSelect!=='1'">
                <v-select
                    class="mb-2"
                    outlined
                    hide-details
                    dense
                    :menu-props="{overflowY:true,offsetOverflow:true,offsetY:true}"
                    item-value="index"
                    v-model="mod[item.id]"
                    :key="'mod2'+item.id"
                    :items="item.select"
                >
                </v-select>
              </template>
              <div
                  v-else
                  class="mt-2"
                  :key="'mod2'+item.id">
                <v-item-group
                    v-model="mod[item.id]"
                    :mandatory="item.required==='1'"
                    :multiple="item.multiSelect==='1'"
                >
                  <v-item
                      v-for="(s,index) in item.select"
                      :key="'mod111'+index"

                  >
                    <v-card
                        class="mb-2 text-body-2 d-flex"
                        elevation="0">
                      <div class="text-body-2 flex-grow-1 mr-2">
                        <div>{{ s.text }}</div>
                        <div>{{ s.priceInfo }}</div>
                      </div>
                      <div class="d-flex justify-center align-center">
                        <v-btn
v-if="s.count > 0"
icon>
                          <v-icon @click.stop="countMinus(s)">
                            mdi-minus-circle
                          </v-icon>
                        </v-btn>
                        <div
v-if="s.count > 0"
class="text-body-1 font-weight-bold mx-2" >{{ s.count }}</div>
                        <v-btn
:disabled="totalAttributesCount >= item.maxCount"
icon>
                          <v-icon @click.stop="countPlus(s)">
                            mdi-plus-circle
                          </v-icon>
                        </v-btn>
                      </div>

                    </v-card>
                  </v-item>
                </v-item-group>
              </div>
            </template>
          </div>
        </template>
        <div
            style="height: 144px;width: 100%"
            class="flex-shrink-0"></div>
        <v-card
            tile
            color="grey lighten-4"
            style="position: fixed;bottom: 0;width: 100%;max-width: 600px"
            class="pa-2 px-4">
          <div class="d-flex align-center">
            <v-card
                elevation="0"
                @click="cancel"
                style="border-radius: 24px"
                color="error lighten-4"
                class="pa-3 mr-2 d-flex align-center">
              <v-icon>
                mdi-close
              </v-icon>
            </v-card>
            <v-spacer></v-spacer>
            <template v-if="isActive">
              <div
                  class=" d-flex align-center grey lighten-5 mr-1"
                  style="border-radius: 24px;">
                <v-card
                    color="grey lighten-3"
                    class="pa-3"
                    style="border-radius: 24px"
                    elevation="0"
                    @click="count>1?count--:null">
                  <v-icon color="black">mdi-minus</v-icon>
                </v-card>
                <div class="pa-2 mx-1">
                  {{ count }}
                </div>
                <v-card
                    color="grey lighten-1"
                    class="pa-3"
                    style="border-radius: 24px"
                    elevation="0"
                    @click="count++">
                  <v-icon color="black">mdi-plus</v-icon>
                </v-card>
              </div>
              <v-card
                  class="d-flex align-center justify-center pa-3 px-4 font-weight-black"
                  style="border-radius: 24px"
                  elevation="0"
                  color="orange lighten-4 black--text"
                  @click="submitModification"
              >
                <v-icon
                    class="mr-2"
                    color="black">mdi-check
                </v-icon>
                {{ realPrice * count | priceDisplay }}
              </v-card>
            </template>
            <template v-else>
              <div class="text-body-1 font-weight-black">{{ realPrice |priceDisplay}}</div>
            </template>

          </div>


        </v-card>
      </div>

    </v-card>
  </v-bottom-sheet>
</template>

<script>
import {findIndex} from "lodash";
import {sumBy} from "lodash-es";

export default {
  name: "DishDetailSheet",
  props: {show: {}, dish: {}, imageRoot: {}},
  data: function () {
    return {
      ids: [],
      selected: false,
      mod: {},
      count: 1,
    };
  },
  watch: {
    show(val) {
      setTimeout(() => {
        if (val) {
          this.computedOption.forEach(it => {
            if (it.multiSelect !== '1') {
              this.$set(this.mod, it.id, 0)
            }
          })
        } else {
          this.ids = []
        }
      }, 200)
    }
  },
  computed: {
    totalAttributesCount () {
      return this.ids.length
    },
    attrLimit() {
      return parseInt(this.dish?.attrLimit ?? -1)
    },
    isActive() {

      return this.dish.isActive === '1'
    },
    options() {
      return this.dish.modInfo ?? []
    },
    haveImage() {
      const image = (this.dish?.image ?? '').toLowerCase()
      return image.endsWith('.png') || image.endsWith('jpg') || image.endsWith('.jpeg')
    },
    computedOption: function () {
      const realModInfo = []

      this.options.forEach((item) => {
        let i = 0
        item.select = []
        if (!Array.isArray(item.selectName)) {
          item.selectName = item.selectName.split(',')
          item.selectValue = item.selectValue.split(',')
          item.priceInfo = item.priceInfo.split(',')
        }
        item.selectName.forEach((name, index) => {
          item.select.push({
            index: i,
            count: this.ids.filter(it => it === item.selectValue[index]).length,
            text: `${name}`,
            value: item.selectValue[index],
            price: parseFloat(item.priceInfo[index]),
            priceInfo: parseFloat(item.priceInfo[index]) === 0 ? '' : `(€${parseFloat(item.priceInfo[index]).toFixed(2)})`
          })
          i++
        })
        realModInfo.push(item)
      })
      return realModInfo
    },
    realMod: function () {
      const realMod = []
      for (const groupId in this.mod) {
        for (const selectIndex of [this.mod[groupId]].flat()) {
          realMod.push({
            groupId,
            selectIndex
          })
        }
      }
      return realMod
    },
    multipleSelected() {
      let total = 0
      if (this.computedOption.length > 0) {
        const res = this.computedOption.filter(it => it.multiSelect === '1' && it.required === '0')
        for (const item of res) {
          total += sumBy(item.select, (x) => {return x.count})
        }
        return total
      } else {
        return 0
      }
    },
    addPrice: function () {
      let total = 0
      if (this.computedOption.length > 0) {
        for (const item of this.computedOption) {
          total += sumBy(item.select, (x) => {return x.count * x.price})
        }
        return total
      } else {
        return 0
      }
    },
    selectedModString: function () {
      return this.computedOption.length > 0 ? this.realMod
          .map(it => this.findModItemUseGroupIdAndIndex(it.groupId, it.selectIndex)?.text ?? '').join(', ') : ''
    },
    realPrice: function () {
      return parseFloat(this.dish.price) + parseFloat(this.addPrice)
    },
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    }
  },
  methods: {
    countPlus (item) {
      this.ids.push(item.value)
    },
    countMinus(item) {
      const res = findIndex(this.ids,(x) => {return x === item.value})
      this.ids.splice(res,1)
    },
    findModItemUseGroupIdAndIndex(groupId, index) {
      const res = this.computedOption.find(g => parseInt(g.id) === parseInt(groupId))
          .select[parseInt(index)]
      res.totalPrice = res.count * res.price
      console.log(res,'res')
      return res
    },
    cancel() {
      this.$emit('modification-cancel')
      this.count = 1
      this.mod = {}
    },
    submitModification() {
      this.$emit('modification-submit', this.mod, this.count)
      this.mod = {}
      this.ids = []
      this.count = 1
    }
  }
}
</script>

<style scoped>
.contentContainer {
  padding: 16px;
}

::-webkit-scrollbar {
  display: none;
}


.name {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: rgb(32, 33, 37);
  margin: 0px;
}

</style>
